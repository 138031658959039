import location from "../../assets/icons/pin.png";
import email from "../../assets/icons/envelope.png";
import mobile from "../../assets/icons/iphone.png";
import phone from "../../assets/icons/telephone.png";

const contactIcons = {
  address: location,
  email: email,
  mobile: mobile,
  phone: phone,
};

export default contactIcons;

module.exports = {
  seo: {
    title: "Jenko - Contact Us",
    description: "",
  },
  title: "Contact Us",
  contactInfo: [
    {
      label: "address",
      value: "HaKormim 28, Ashkelon",
    },
    {
      label: "email",
      value: "ky@jenko-team.com",
    },
    {
      label: "mobile",
      value: "+972 050 - 5771214",
    },
    // {
    //   label: "phone",
    //   value: "+972  050 - 5771214",
    // },
  ],
  formPlaceHolders: {
    name: "Full Name",
    email: "E-Mail",
    message: "Leave a message",
    cta: "Send",
  },
  tyMessage: [
    "Thank you for reaching out.",
    "One of our representatives will contact you soon.",
  ],
  errorMessage: ["Something went Wrong!", "You can try again..."],
  dir: "ltr",
};

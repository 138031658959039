import React, { useState } from 'react'
import contactIcons from '../../data/components/contactIcons'
import { handleFormSubmit } from '../../utils/utils';

const ContactForm = (props) => {
  const [formData, setFormData] = useState({})
  const [formStatus, setFormStatus] = useState(false)
  const {title, contactInfo, formPlaceHolders, tyMessage, errorMessage} = props.content;
  const {sCfContactForm, sCfFormWrapper, sCfContactWrapper, sCfImgWrapper, sFormSubmited} = props.styles;
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    setFormStatus('loading')
    handleFormSubmit(e, formData, setFormStatus)
  }
  const formSubmited = () => (
      <div className={sFormSubmited}>
        {  
          formStatus === 'loading' ?
          <div className='loader'></div>
          :
          formStatus === 'ok' ?
          <><p>{tyMessage[0]}</p><p>{tyMessage[1]}</p></> 
          :
          <><p>{errorMessage[0]}</p><p>{errorMessage[1]}</p> </>    
        }   
     </div>
  )
  
  return(
      <div className={sCfContactForm}>
         <div className={sCfContactWrapper}>
             <ul>
               {
               contactInfo.map(
                 (contactElem, index) => (
                   <li key={index}>
                      <div className={sCfImgWrapper}>
                          <img src={contactIcons[contactElem.label]} alt={contactElem.label} />
                      </div>
                      <span>
                        {contactElem.value}
                      </span>
                   </li>
                 ))
               }
             </ul>
         </div>
         <div className={sCfFormWrapper}>
           { 
                     formStatus ?  
                     <form >
                         <h2>{title}</h2>
                          {formSubmited()}
                     </form>
                     :
                     <form
                          name="contact-us-form"
                          onSubmit={handleSubmit}
                    >
                          <h2>{title}</h2>
                          <input 
                              placeholder={formPlaceHolders.name}
                              name="name" 
                              type="text"
                              required
                              onChange={handleChange} 
                              />
                          <input 
                              placeholder={formPlaceHolders.email}
                              type="email"
                              name="email"
                              required
                              onChange={handleChange} 
                                              
                            />
                          <textarea
                              placeholder={formPlaceHolders.message}
                              name="message"
                              required
                              onChange={handleChange} 
                              />
                          <button type="submit" >{formPlaceHolders.cta}</button>
                      </form>
           }
        </div>
    </div>
  )
   
}

export default ContactForm
